import axios from "axios";

// 前端直接访问该平台会出CORS跨域错误，
// 正常使用需要服务器代理中转
// 之前在淮安服务器里有的。
axios.defaults.baseURL = "http://47.104.99.106/hxapi"


//axios.defaults.baseURL = "http://192.168.20.13:38080/hxapi"
var authToken = null

axios.interceptors.request.use(function (config) {
    if (authToken != null) {
        config.headers.Authorization = 'Bearer ' + authToken
    }

    return config
}, function (err) {
    console.log('[溯源仪接口错误] ' + err)
})
/**
 * 登
 * 
 * 
 */
const userName = "haqah"
const pwd = "29d4cde78b7fb26d1edb4c554b5b61bc"
async function login() {
    // 登录

    await axios.get(
        "/Login", {
        params: {
            userName: userName,
            pwd: pwd
        }
    }
    ).then(resp => {
        const content = resp.data
        if (content.Code === 1) {
            authToken = content.Data.token
            console.log("溯源仪接口登录成功！")
        }
    })

}

async function getLineChart() {
    let chartData = null;
    await axios.get(
        '/Observation/GetLineChart?equipmentId=39&startTime=0001-01-01+00:00:00&endTime=0001-01-01+00:00:00'
    ).then(resp => {
        const content = resp.data
        if (content.Code === 1) {
            chartData = content.Data
        }
    })
    return chartData
}


async function getRecords() {
    let records = null;

    const pageQuery = {
        PageIndex: 1,
        PageSize: 1,
        OrderField: ['CreateTime'],
        OrderType: ['desc']
    }

    const filterColumns = [
        {
            filters: [{
                data: "2022-09-28T19:15",

            }, { "data": "2022-10-17T19:15" }],
            columnid: "3",
            columnname: "CreateTime",
            tablename: "BGWater"
        }
    ]
    await axios.get(
        '/Observation/GetListPage',
        {
            params: {
                pageQuery: pageQuery,
                filterColumns: filterColumns,
            }
        }
    ).then(resp => {
        const content = resp.data
        if (content.Code === 1) {
            records = content.Data.list
        }
    })

    return records
}

async function calcComparisonResult(id) {
    let ComparisonResult = null;
    await axios.get(
        '/Observation/Calc?observationId=16858&bgwaterId=-1&regionIds=10',
        {
            params: {
                observationId: id,
                bgwaterId: -1,
                regionIds: 10
            }
        }
    ).then(resp => {
        const content = resp.data
        if (content.Code === 1) {
            ComparisonResult = content.Data.ComparisonResult
        }
    })

    return ComparisonResult
}
export {
    login,
    getLineChart,
    getRecords,
    calcComparisonResult
}
<template>
  <!--污染物溯源仪页面-->
  <div class="source">
    <div class="source-header">污染物溯源</div>
    <!--body-->
    <div class="panel">
      <div>
        <div class="header">
          <div class="title">
            <span>近期峰值变化图</span>
          </div>
        </div>
        <div id="report-chart"></div>
      </div>
    </div>
    <div class="table"></div>

    <div class="header">
      <div class="title">
        <span>最新记录 </span>
      </div>
    </div>

    <!---->

    <div class="table">
      <div class="item">
        <div>检测时间</div>
        <div>背景水时间</div>
        <div>位置</div>
      </div>
      <div class="item">
        <div>{{ latestRecord.CreateTime }}</div>
        <div>{{ latestRecord.BGWaterTime }}</div>
        <div>{{ latestRecord.Address }}</div>
      </div>
    </div>
    <!--最新记录-->

    <div class="table">
      <div class="item">
        <div>序号</div>
        <div>激发波长</div>
        <div>发射波长</div>
        <div>峰强度</div>
      </div>
      <div v-for="(peak, index) of latestRecord.Peaks" :key="index" class="item">
        <div>{{ index + 1 }}</div>
        <div>{{ peak[0] }}</div>
        <div>{{ peak[1] }}</div>
        <div>{{ peak[2] }}</div>
      </div>
    </div>
    <div class="table">
      <div class="item">
        <div>匹配结果</div>
      </div>
      <div v-for="(result, index) of comparisonResult" :key="index" class="item">
        <div>{{ result }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import moment from "moment";
import {
  login,
  getLineChart,
  getRecords,
  calcComparisonResult,
} from "./hxaj-api";
echarts.use([
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  data() {
    return {
      chartData: {}, // 近期的数据变化图所需数据
      latestRecord: {}, // 近期的
      comparisonResult: {},
    };
  },

  mounted() {
    // 图表控件大小设置与初始化
    var chartDom = document.getElementById("report-chart");
    chartDom.style.height = "25vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    //this.renderChart();

    this.now = moment().format("YYYY-MM-DD HH:00");
    // 获取数据
    this.initAPI();
  },

  methods: {
    async initAPI() {
      await login(); // 先登录

      this.chartData = await getLineChart();
      this.renderChart();

      const listData = await getRecords();
      console.log(listData);
      this.latestRecord = listData[0];
      const id = this.latestRecord.Id;
      this.comparisonResult = await calcComparisonResult(id);
    },
    renderChart() {
      const { xAxis = {}, yAxis = {} } = {};

      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          valueFormatter: (value) => value,
        },

        legend: {
          left: "center",
          top: "bottom",
          textStyle: {
            color: "#999",
          },
        },

        grid: {
          show: false,
          left: "5%",
          top: "10%",
          bottom: "15%",
          right: "2%",
        },

        color: ["#99F3B3", "#75D7FF", "#F8DE74"],

        xAxis: {
          type: "category",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "#999",
          },
          ...xAxis,
          data: this.chartData.createtime,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },

          axisLabel: {
            color: "#999",
            formatter: "{value}",
          },

          ...yAxis,
        },

        series: [
          {
            name: "峰强度",
            type: "line",
            smooth: true,
            data: this.chartData.height,
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>


<style scoped>
.source {
  position: fixed;
  top: 11vh;
  left: 4vw;
  right: 4vw;
  z-index: 99;

  background-color: var(--bg-color);

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.source>.source-header {
  background-color: var(--theme-color);
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1vw;
  line-height: 4vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header {
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .title {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  position: relative;
}

.header .title img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header .date {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
}

.table {
  margin: 12px;
}

.table .item {
  display: flex;
  align-items: center;
}

.table .item div {
  height: 32px;
  line-height: 32px;
  margin: 0 1px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  flex: 1;
  /* background: rgba(117, 215, 255, 0.2); */
  background-color: #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table .item:first-child div {
  background-color: var(--theme-color);
  color: #fff;
}

.panel {
  height: 30vh;
}

.record-info {
  display: flex;
}
</style>